import api from '../../../globals/client';
import config from '../../../config/app.config';
var promise;

export default class ReservationService {
    details(data) {
        promise =  api.client.get(`${config.end_point.qr.reservation}-details`, {params: data});
        return promise;
    }

    search(data) {
        promise =  api.client.get(`${config.end_point.qr.reservation}-search`, {params: data});
        return promise;
    }
}

export const reservationService = new ReservationService();
