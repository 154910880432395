import { reservationService } from "../../../../services/qr/reservation/reservation.service";

export default {
  components: {},
  data() {
    return {
      data: [],
      filter: {},
      car: {
        paginate: 0,
        per_page: 0,
      },
      qr: {
        paginate: 0,
        per_page: 0,
      },
      config: {
        error: false,
      },
    };
  },
  async mounted() {
    // await this.list(this.qr);
    if (this.$route.query.email && this.$route.query.passcode) {
      this.qr.reservation_id = `${this.$route.query.email}|${this.$route.query.passcode}`;
      await this.details(this.$route.query);
    }
  },
  methods: {
    async search(query) {
      this.data = [];
      await reservationService
        .search(query)
        .then((result) => {
          this.data = result.data;
          this.config.error = false;
        })
        .catch(() => {
          this.config.error = true;
        });
    },

    async details(query) {
      this.data = [];
      await reservationService
        .details(query)
        .then((result) => {
          this.data = result.data;
          this.config.error = false;
        })
        .catch(() => {
          this.data = [];
          this.config.error = true;
        });
    },
    async mapParams() {
      const params = this.qr.reservation_id.split("|");
      if (params.length == 2) {
        const email = params.find((el) => el.indexOf("@") !== -1);
        const valid = this.validateEmail(email);
        if (valid) {
          const query = {
            email: email,
            passcode: params.find((el) => el != email),
          };
          this.details(query);
        }
      }
    },
    clear() {
      this.qr = {
        id: 1,
        paginate: 0,
        per_page: 0,
      };
      this.car = {
        paginate: 0,
        per_page: 0,
      };
      this.data = [];
      this.config.error = false;
    },
  },
};
